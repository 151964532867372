// Increase z-index for components menu otherwise map hides menu extensions
.dropdown-pane {
  z-index: 1000;
}
// Override padding in this component
.wrapper {
  padding: 0;

  .callout.announcement {
    margin-top: 2rem;
  }

  .google-map {
    margin-bottom: 0;
  }
}

#awesome-map {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  // .leaflet-marker-icon.svg-icon svg path{
  //   filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, .7));
  // }

  .loading-spinner {
    z-index: 10000;
    position: absolute;
    right: 0;
    top: 0;
  }

  .leaflet-container {
    font: inherit;

    #bodyContent .description {
      img,
      iframe {
        max-height: 5em;
      }
    }
  }

  .leaflet-control-layers-list {
    hr {
      margin: 0.5em 0;
    }

    b {
      margin: 1em 0;
      display: block;
    }

    label {
      line-height: 1;
      font-size: 0.8rem;
      margin: 0 0.5em 0.5em;

      i {
        display: inline-block;
        width: 0.8rem;
        height: 0.8rem;
        background-color: var(--primary);
      }
    }

    input {
      margin: 0;
    }
  }

  .map-legend {
    background: rgba(255, 255, 255, 0.8);
    padding: 1em;
    border: 2px solid #bbb;
    border-radius: 4px;
  }

  .awesome_map-title-control {
    display: block;
    cursor: pointer;

    &::after {
      content: "";
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid black;
      border-bottom: 0;
      display: inline-block;
      margin: 0 0 2px 5px;
    }
  }

  .active {
    .awesome_map-title-control::after {
      border-top: 0;
      border-bottom: 5px solid black;
    }
  }
}

#awesome_map- {
  &categories-control {
    display: none;

    label {
      margin-left: 0;
      display: none;

      i {
        margin-left: 0.5em;
        display: inline-block;
        width: 0.8rem;
        height: 0.8rem;
        border-radius: 50%;
        background-color: var(-- primary);
      }

      &.subcategory {
        padding-left: 1em;
      }
    }

    .categories-container {
      display: none;
    }

    &.active {
      .categories-container {
        display: block;
      }
    }
  }

  &hashtags-control {
    display: none;

    label {
      display: inline-block;
      line-height: 1;
      background-color: #f0f0f0;
      border-radius: 5px;
      margin: 0.5rem;
      padding: 0.5rem;

      span {
        margin: 0 0.5rem 2px 0;
      }

      input {
        vertical-align: top;
      }
    }

    .awesome_map-toggle_all_tags {
      margin: 0.5rem 1rem;
      font-size: 0.875rem;
    }

    .hashtags-container,
    .awesome_map-toggle_all_tags {
      display: none;
    }

    &.active {
      .hashtags-container,
      .awesome_map-toggle_all_tags {
        display: block;
      }
    }
  }
}
